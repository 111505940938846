/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a",
    h2: "h2",
    code: "code",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Admonition, FilteredContent, ResponseSample} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  if (!FilteredContent) _missingMdxReference("FilteredContent", true);
  if (!ResponseSample) _missingMdxReference("ResponseSample", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "To check that you have correctly coded your integration to our API specifications, complete our test cases for each API resource. Each API resource has a unique test endpoint and a unique set of test cases."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "If your tests are successful:"), "\nWe send you an email to inform you that you can now send requests to the live endpoint."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "If your tests are unsuccessful:"), "\nContact the project owner or email us for help at ", React.createElement(_components.a, {
    href: "mailto:integrationsupport@payroc.com"
  }, "integrationsupport@payroc.com"), "."), "\n", React.createElement(_components.h2, null, "Test environment"), "\n", React.createElement(_components.p, null, "Run the following tests using our ", React.createElement(_components.strong, null, "test"), " environment with the credentials that we provided you."), "\n", React.createElement(Admonition, {
    type: "positive"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test environment base URI:"), " ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1"
  }, "https://api.uat.payroc.com/v1"))), "\n", React.createElement(_components.h2, null, "Testing Funding Recipients"), "\n", React.createElement(_components.p, null, "Use our ", React.createElement(_components.code, null, "/funding-recipients"), " endpoint to create and manage third-party recipients that receive\nfunds from your transactions. To verify that your integration works with this endpoint,\nrun the following tests:"), "\n", React.createElement(_components.h3, null, "Test 1: Create a Funding Recipient"), "\n", React.createElement(_components.p, null, "You can create a Funding Recipient independently of any existing MIDs on your account."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use our ", React.createElement(_components.code, null, "/funding-recipients"), " endpoint to create a new Funding Recipient."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Successful response:")), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(ResponseSample, {
    operationId: "createFundingRecipient"
  })), "\n", React.createElement(_components.h3, null, "Test 2: Add a Funding Account to a Recipient"), "\n", React.createElement(_components.p, null, "You can use our ", React.createElement(_components.code, null, "/funding-recipients"), " endpoint to manage Funding Accounts\nassociated with a Recipient."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use our ", React.createElement(_components.code, null, "/funding-recipients"), " endpoint to add a new Funding Account to an existing Recipient."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Successful response:")), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(ResponseSample, {
    operationId: "createFundRecipientFundingAccount"
  })), "\n", React.createElement(_components.h3, null, "Test 3: Add a new Owner to a Recipient"), "\n", React.createElement(_components.p, null, "You can use our ", React.createElement(_components.code, null, "/funding-recipients"), " endpoint to\ncreate and manage Owner objects associated with the Funding Recipient."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use our ", React.createElement(_components.code, null, "/funding-recipients"), " to create a new Owner."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Successful response:")), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(ResponseSample, {
    operationId: "createFundRecipientOwner"
  })), "\n", React.createElement(_components.h3, null, "Test 4: Retrieve the Funding Accounts for a Recipient"), "\n", React.createElement(_components.p, null, "To create accurate Funding Instructions, it's important to make sure that you can retrieve a list of Funding Accounts\nfor any Funding Recipients that you create."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use our ", React.createElement(_components.code, null, "/funding-accounts"), " endpoint to retrieve a list of Funding Accounts for a Funding Recipient."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Successful response:")), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(ResponseSample, {
    operationId: "listFundRecipientFundingAccounts"
  })), "\n", React.createElement(_components.h2, null, "Testing Funding Instructions"), "\n", React.createElement(_components.p, null, "You can use our ", React.createElement(_components.code, null, "/funding-instructions"), " endpoint to tell us how to split your available funds across\nmultiple Funding Accounts. For example, Funding Accounts that belong to your sales office, your merchants, or any Funding Recipients.\nTo verify that your integration works with this endpoint, run the following tests:"), "\n", React.createElement(_components.h3, null, "Test 5: Create a new Funding Instruction"), "\n", React.createElement(_components.p, null, "A Funding Instruction tells us how to split funds across multiple accounts."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use our ", React.createElement(_components.code, null, "/funding-accounts"), " endpoint to retrieve a list of Funding Accounts."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use our ", React.createElement(_components.code, null, "/funding-instructions"), " endpoint to create a new Funding Instruction."), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Successful response:")), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(ResponseSample, {
    operationId: "createInstruction"
  })), "\n", React.createElement(_components.h3, null, "Test 6: Update a Funding Instruction"), "\n", React.createElement(_components.p, null, "While a Funding Instruction has a status of ", React.createElement(_components.code, null, "accepted"), ", you can continue to edit and update\nthe instruction. To update a Funding Instruction, you must send a ", React.createElement(_components.code, null, "PUT"), " request to the correct\nFunding Instruction id."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use our ", React.createElement(_components.code, null, "/funding-instructions"), " endpoint to update a Funding Instruction."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Successful response:")), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(ResponseSample, {
    operationId: "createInstruction"
  })), "\n", React.createElement(_components.h3, null, "Test 7: List Funding Activity"), "\n", React.createElement(_components.p, null, "You can use our ", React.createElement(_components.code, null, "/funding-activity"), " endpoint to retrieve a list of all funding activity so far."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use our ", React.createElement(_components.code, null, "/funding-activity"), " endpoint to retrieve a list of all funding activity."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Successful response:")), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(ResponseSample, {
    operationId: "getFundingActivity"
  })), "\n", React.createElement(_components.h2, null, "Results"), "\n", React.createElement(_components.p, null, "After you complete your tests, send your results to ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "mailto:integrationsupport@payroc.com"
  }, "integrationsupport@payroc.com")), ".\nA member of our team will contact you to confirm certification or to provide support."), "\n", React.createElement(_components.h2, null, "Need more help?"), "\n", React.createElement(_components.p, null, "We are here to help! If you need help with your integration, contact us at ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "mailto:integrationsupport@payroc.com"
  }, "integrationsupport@payroc.com")), " or through our ", React.createElement(_components.strong, null, "Developer Community"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
